.calculator-margin {
    padding-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
}

.calculator-btn {
    border: 0 !important;
    font-size: 12px !important;
}

.round {
    -webkit-border-radius: 0.3rem;
    border-radius: 0.3rem;
}
.round img {
    -webkit-border-radius: 0.3rem;
    border-radius: 0.3rem;
}

.box-icon,
[class^="box-icon-"],
[class*=" box-icon-"] {
    z-index: 2;
    padding: inherit;
    position: relative;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    display: block;
    background: #256be6;
    color: #fff;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}
.box-icon:hover,
[class^="box-icon-"]:hover,
[class*=" box-icon-"]:hover {
    background: #256be6;
    color: #fff;
}

.box-icon:before,
[class^="box-icon-"]:before,
[class*=" box-icon-"]:before {
    display: inline-block;
}

.box-icon-big {
    width: 60px;
    height: 60px;
    line-height: 60px !important;
    font-size: 28px !important;
}

.box-icon-border,
[class^="box-icon-border"],
[class*=" box-icon-border"] {
    background: none;
    border: 1px solid #256be6;
    color: #256be6;
}
.box-icon-border:hover,
[class^="box-icon-border"]:hover,
[class*=" box-icon-border"]:hover {
    background: #256be6;
    color: #fff !important;
}
.box-icon-border.box-icon-black,
[class^="box-icon-border"].box-icon-black,
[class*=" box-icon-border"].box-icon-black,
.box-icon-border.box-icon-to-black:hover,
[class^="box-icon-border"].box-icon-to-black:hover,
[class*=" box-icon-border"].box-icon-to-black:hover {
    border-color: #333;
    color: #333;
}
.box-icon-border.box-icon-black:hover,
[class^="box-icon-border"].box-icon-black:hover,
[class*=" box-icon-border"].box-icon-black:hover,
.box-icon-border.box-icon-to-black:hover:hover,
[class^="box-icon-border"].box-icon-to-black:hover:hover,
[class*=" box-icon-border"].box-icon-to-black:hover:hover {
    background: #333;
}
.box-icon-border.box-icon-gray,
[class^="box-icon-border"].box-icon-gray,
[class*=" box-icon-border"].box-icon-gray,
.box-icon-border.box-icon-to-gray:hover,
[class^="box-icon-border"].box-icon-to-gray:hover,
[class*=" box-icon-border"].box-icon-to-gray:hover {
    border-color: #808080;
    color: #808080;
}
.box-icon-border.box-icon-gray:hover,
[class^="box-icon-border"].box-icon-gray:hover,
[class*=" box-icon-border"].box-icon-gray:hover,
.box-icon-border.box-icon-to-gray:hover:hover,
[class^="box-icon-border"].box-icon-to-gray:hover:hover,
[class*=" box-icon-border"].box-icon-to-gray:hover:hover {
    background: #808080;
}
.box-icon-border.box-icon-white,
[class^="box-icon-border"].box-icon-white,
[class*=" box-icon-border"].box-icon-white,
.box-icon-border.box-icon-to-white:hover,
[class^="box-icon-border"].box-icon-to-white:hover,
[class*=" box-icon-border"].box-icon-to-white:hover {
    border-color: #fff;
    color: #fff;
}
.box-icon-border.box-icon-white:hover,
[class^="box-icon-border"].box-icon-white:hover,
[class*=" box-icon-border"].box-icon-white:hover,
.box-icon-border.box-icon-to-white:hover:hover,
[class^="box-icon-border"].box-icon-to-white:hover:hover,
[class*=" box-icon-border"].box-icon-to-white:hover:hover {
    color: #256be6 !important;
    background: #fff;
}
.box-icon-border.box-icon-info,
[class^="box-icon-border"].box-icon-info,
[class*=" box-icon-border"].box-icon-info,
.box-icon-border.box-icon-to-info:hover,
[class^="box-icon-border"].box-icon-to-info:hover,
[class*=" box-icon-border"].box-icon-to-info:hover {
    border-color: #2f96b4;
    color: #2f96b4;
}
.box-icon-border.box-icon-info:hover,
[class^="box-icon-border"].box-icon-info:hover,
[class*=" box-icon-border"].box-icon-info:hover,
.box-icon-border.box-icon-to-info:hover:hover,
[class^="box-icon-border"].box-icon-to-info:hover:hover,
[class*=" box-icon-border"].box-icon-to-info:hover:hover {
    background: #2f96b4;
}
.box-icon-border.box-icon-success,
[class^="box-icon-border"].box-icon-success,
[class*=" box-icon-border"].box-icon-success,
.box-icon-border.box-icon-to-success:hover,
[class^="box-icon-border"].box-icon-to-success:hover,
[class*=" box-icon-border"].box-icon-to-success:hover {
    border-color: #51a351;
    color: #51a351;
}
.box-icon-border.box-icon-success:hover,
[class^="box-icon-border"].box-icon-success:hover,
[class*=" box-icon-border"].box-icon-success:hover,
.box-icon-border.box-icon-to-success:hover:hover,
[class^="box-icon-border"].box-icon-to-success:hover:hover,
[class*=" box-icon-border"].box-icon-to-success:hover:hover {
    background: #51a351;
}
.box-icon-border.box-icon-warning,
[class^="box-icon-border"].box-icon-warning,
[class*=" box-icon-border"].box-icon-warning,
.box-icon-border.box-icon-to-warning:hover,
[class^="box-icon-border"].box-icon-to-warning:hover,
[class*=" box-icon-border"].box-icon-to-warning:hover {
    border-color: #f89406;
    color: #f89406;
}
.box-icon-border.box-icon-warning:hover,
[class^="box-icon-border"].box-icon-warning:hover,
[class*=" box-icon-border"].box-icon-warning:hover,
.box-icon-border.box-icon-to-warning:hover:hover,
[class^="box-icon-border"].box-icon-to-warning:hover:hover,
[class*=" box-icon-border"].box-icon-to-warning:hover:hover {
    background: #f89406;
}
.box-icon-border.box-icon-danger,
[class^="box-icon-border"].box-icon-danger,
[class*=" box-icon-border"].box-icon-danger,
.box-icon-border.box-icon-to-danger:hover,
[class^="box-icon-border"].box-icon-to-danger:hover,
[class*=" box-icon-border"].box-icon-to-danger:hover {
    border-color: #bd362f;
    color: #bd362f;
}
.box-icon-border.box-icon-danger:hover,
[class^="box-icon-border"].box-icon-danger:hover,
[class*=" box-icon-border"].box-icon-danger:hover,
.box-icon-border.box-icon-to-danger:hover:hover,
[class^="box-icon-border"].box-icon-to-danger:hover:hover,
[class*=" box-icon-border"].box-icon-to-danger:hover:hover {
    background: #bd362f;
}
.box-icon-border.box-icon-inverse,
[class^="box-icon-border"].box-icon-inverse,
[class*=" box-icon-border"].box-icon-inverse,
.box-icon-border.box-icon-to-inverse:hover,
[class^="box-icon-border"].box-icon-to-inverse:hover,
[class*=" box-icon-border"].box-icon-to-inverse:hover {
    border-color: #127cdc;
    color: #127cdc;
}
.box-icon-border.box-icon-inverse:hover,
[class^="box-icon-border"].box-icon-inverse:hover,
[class*=" box-icon-border"].box-icon-inverse:hover,
.box-icon-border.box-icon-to-inverse:hover:hover,
[class^="box-icon-border"].box-icon-to-inverse:hover:hover,
[class*=" box-icon-border"].box-icon-to-inverse:hover:hover {
    background: #127cdc;
}
.box-icon-border.box-icon-to-normal:hover,
[class^="box-icon-border"].box-icon-to-normal:hover,
[class*=" box-icon-border"].box-icon-to-normal:hover {
    border-color: #ed1c24;
    background: #ed1c24;
}
.box-icon-border-dashed {
    border-style: dashed;
}

.container-header-calc {
    overflow: hidden;
    background-color: #256be6;
    color: #FFF;
    font-weight: 900;
    border-top: 1px solid #e6e6e6;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
}

.btn.btn-payment {
    background: #256be6 !important;
    color: #ffffff !important;
}

.btn.btn-payment:hover {
    background: #111111;
    z-index: -1;
    transition: all 0.4s ease-in-out 0s;
}

.mb15 {
    margin-bottom: 15px !important;
}